.terminalContent > div:not(:first-child) {
  margin-top: 1em;
}

.terminalContent > p {
  margin-top: 0.25em !important;
}

.terminalInputArea::after {
  display: block;
  content: '</>';
}

.welcomeMessage {
  display: block;
}

.welcomeMessage:last-child {
  margin-bottom: 1em;
}

.consoleSpan {
  margin-top: 0.25em !important;
  display: block;
}

.entryFrame {
  background: white;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  overflow: scroll;
}
